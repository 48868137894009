<template>
  <div id="homeImg" :style="{ backgroundImage: `url(${this.globalConfig.RESOURCE_PREFIX}/MBL_E-handbook_Design/1st_layer/1st_layer.jpg)` }">
    <router-link to="/WiperSystems" tag="div" class="click-area" style="left: 22.5%; top: calc(10.5% - 0px); width: 77%; height: 10%;"></router-link>
    <router-link to="/ThermalSystems" tag="div" class="click-area" style="left: 0%; top: calc(22.5% - 0px); width: 77%; height: 10%;"></router-link>
    <router-link to="/ComfortActuators" tag="div" class="click-area" style="left: 22.5%; top: calc(34.5% - 0px); width: 77%; height: 10%;"></router-link>
    <router-link to="/PowerActuators" tag="div" class="click-area" style="left: 0%; top: calc(46% - 0px); width: 77%; height: 10%;"></router-link>
    <div>
        <span style="
      position: absolute;
      bottom: 0px;
      font-size: 12px;
      left: 3px;
  ">沪ICP备10002431号
      </span>
      <span style="
          bottom: 0px;
          position: absolute;
          right: 3px;
          font-size: 12px;
      ">沪公网安备31010502000766号
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data: () => ({
    loading: false,
    items: [
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me' }
      ],
    offset: true
  }),
  mounted(){
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style lang="sass">
.home
  height: calc(100% - 0px)

#homeImg
  position: relative
  height: 100%
  background-position: 0px 0px !important
  background-size:100%  110%
  max-width: 100% !important

h4
  font-size: 20px
  line-height: 1.25
  font-family: BoschSans-Light,Helvetica,Arial,sans-serif
  color: #005691
  font-style: normal
p
  font-size: 16px !important
  line-height: 1.5 !important
  font-family: BoschSans-Light,Helvetica,Arial,sans-serif !important

.click-area
  position: absolute
  z-index: 1
</style>
